import * as React from "react";
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import WhatWeDo from './components/WhatWeDo';
import Modules from './components/Modules';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import { BrowserRouter } from "react-router-dom";

export default function App() {

return (
    <BrowserRouter>
      <div style={background}>
        <Header />
        <Hero />
        <About />
        <Services />
        <WhatWeDo />
        <Modules />
        <ContactForm />
        <Footer />
      </div>
    </BrowserRouter>
 );
}

const background = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    background: 'linear-gradient(to right, #30334E,#5A5C72)',
    backgroundColor: '#30334E',
}