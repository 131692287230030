import React, { useState  } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import '../css/Header.css';
import { responsiveFontSizes } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link'; 

export default function Header() {
  const [showNavbar, setShowNavbar] = useState(false)

  const [openInfo, setOpenInfo] = React.useState(false);
  const [loginComingSoonMessage, setLoginComingSoonMessage] = React.useState("Coming soon!");

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  function handleLogin() {
    setOpenInfo(true);
  }

  const handleInfoClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenInfo(false);
};

  return (
    <nav className="navbar">
      <div className="container">
        <div className="brand-name">
          <a to="/">Small-World.ai</a>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <MenuIcon />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <Link to="#">Home</Link>
            </li>
            <li>
              <Link to="#about" smooth>About</Link>
            </li>
            <li>
              <Link to="#services" smooth>Services</Link>
            </li>
            <li>
              <Link to="#modules" smooth>Modules</Link>
            </li>
            <li>
              <Link to="#contact" smooth>Contact</Link>
            </li>
            <li className="login">
              <a to="#" target="_blank" onClick={handleLogin}>Login</a>
            </li>
          </ul>
        </div>
      </div>
      <Snackbar open={openInfo} autoHideDuration={6000} onClose={handleInfoClose}>
          <Alert
              onClose={handleInfoClose}
              severity="info"
              variant="filled"
              sx={{ width: '100%' }}
              >
              {loginComingSoonMessage}
          </Alert>
      </Snackbar>
    </nav>
  );
};
