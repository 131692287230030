import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from './Button';
import '../css/WhatWeDo.css';
import { HashLink as Link } from 'react-router-hash-link'; 

export default function WhatWeDo() {

    function handleClick() {
        alert('Button clicked!');
    };

  return (
        <whatwedo>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3, sm: 8, md: 12 }}>
                    <Grid item xs={6} sm={4} md={6}>
                        <img
                            loading="lazy"
                            srcSet="./assets/what-we-do.jpg"
                            className="inner-table-img"
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={6}>
                        <div className="inner-table-column-2-rows-title">What we do?</div>
                        <div className="inner-table-column-2-rows-info">
                            <span className="inner-table-column-2-rows-info-bold">Personalized AI-Driven Insights:</span>
                            <span className="inner-table-column-2-rows-info-reg">The platform delivers customized reports with actionable
                                insights tailored to each business’s unique needs.</span>
                            <br />
                            <br />
                            <span className="inner-table-column-2-rows-info-bold">Real-Time data and Swift Decisions:</span>
                            <span className="inner-table-column-2-rows-info-reg">The platform’s strength lies in providing instant access
                                to the latest market insights and regulatory updates.</span>
                            <br />
                            <br />
                            <span className="inner-table-column-2-rows-info-bold">Comprehensive Global Expansion Support:</span>
                            <span className="inner-table-column-2-rows-info-reg">offers a holistic solution to navigating the complexities of
                                international scaling.</span>
                        </div>
                        <br />
                        <Link to="#contact" smooth>
                            <Button text="Request a report" />
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </whatwedo>
    );
};