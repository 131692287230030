import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import FactoryIcon from '@mui/icons-material/Factory';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import Button from './Button';
import '../css/Modules.css';
import { HashLink as Link } from 'react-router-hash-link'; 

export default function Modules() {

    function handleClick() {
        alert('Button clicked!');
    };

    return (
        <modules id="modules">
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3, sm: 8, md: 12 }}>
                    <Grid item xs={6} sm={4} md={6}>
                        <img
                            loading="lazy"
                            srcSet="./assets/modules.png"
                            className="modules-main-img"
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <div className="modules-title">Our AI Modules</div>
                        <List>
                            <ListItem>
                                <ListItemIcon sx={{ color: '#fff' }}>
                                    <SettingsSuggestIcon />
                                </ListItemIcon>
                                <ListItemText primary="Operational Cost" className="list-item-text"/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ color: '#fff' }}>
                                    <FactCheckIcon />
                                </ListItemIcon>
                                <ListItemText primary="Regulatory Environment" className="list-item-text"/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ color: '#fff' }}>
                                    <Diversity1Icon />
                                </ListItemIcon>
                                <ListItemText primary="Manpower Availability" className="list-item-text"/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ color: '#fff' }}>
                                    <PriceChangeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Manpower Cost" className="list-item-text"/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ color: '#fff' }}>
                                    <TravelExploreIcon />
                                </ListItemIcon>
                                <ListItemText primary="Flexibility of Employment" className="list-item-text"/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ color: '#fff' }}>
                                    <FactoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Competitive Landscape" className="list-item-text"/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ color: '#fff' }}>
                                    <EmojiTransportationIcon />
                                </ListItemIcon>
                                <ListItemText primary="Easiness of Transportation" className="list-item-text"/>
                            </ListItem>
                        </List>
                        <Link to="#contact" smooth><Button text="Request a report" /></Link>
                    </Grid>
                </Grid>
            </Box>            
        </modules>
    );
};