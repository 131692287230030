import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import '../css/Services.css';
import { HashLink as Link } from 'react-router-hash-link'; 

export default function Services() {

  const cardList = [
    {
      img: "./assets/globalExpansion.png",
      title: "Expansion Strategy",
      description:
        "Discover effective strategies to expand your business and reach new markets. Our customized plans are designed to fuel your growth!",
      buttonText: "Learn how",
    },
    {
      img: "./assets/marketResearch.png",
      title: "Market Research",
      description:
        "Gain valuable insights and make informed decisions with our comprehensive market research services. Uncover new opportunities for your business!",
      buttonText: "Discover",
      },
    {
      img: "./assets/strategicPartnership.jpg",
      title: "Strategic Partnerships",
      description:
        "Optimize your business finances and investments through strategic partnerships. Maximize your potential for sustainable growth!",
      buttonText: "Optimize",
      },
  ];

  return (
    <services id="services">
        <div className="serviceHeader">
          <span className="serviceHeaderTitle">
            Our services
          </span>
          <br />
          <span className="serviceHeaderSubTitle">
            Unlock your business potential
          </span>
        </div>
        <Box sx={{ flexGrow: 1 }} className="box-cards">
          <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 8, md: 12 }}>
            {cardList.map((card, id) => (
              <Grid item xs={3} sm={4} md={4} key={id}>
                <Card sx={{ maxWidth: 345 }} key={id}>
                  <CardActionArea className="card-action-area">
                    <CardMedia
                      component="img"
                      height="140"
                      image={card.img}
                      alt={card.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" className="card-content-title">
                        {card.title}
                      </Typography>
                      <Typography variant="body2" color="#000">
                        {card.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Link to="#contact" smooth>
                      <Button size="small" color="primary">
                        {card.buttonText}
                      </Button>
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
    </services>
  );
};