import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import '../css/Footer.css';

export default function Footer() {

    return (
        <footer>
            <Box sx={{ flexGrow: 1 }} className="box-footer">
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={4} md={12}>
                        <Typography variant="overline" display="block" gutterBottom>
                            © All right reserved to Small-World.ai |  <a href="#" target="_blank" className="footer-url">Privacy Policy</a>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={4} md={12}>
                        <Typography variant="overline" display="block" gutterBottom>
                            Images provided by: <a href="https://www.freepik.com/" target="_blank" className="footer-url">https://www.freepik.com/</a> 
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </footer>
    );
};