import React, { useRef, useState  } from 'react';
import Button from './Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import '../css/ContactForm.css';
import emailjs from '@emailjs/browser';

export default function ContactForm() {
    const [openError, setOpenError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");

    const [companyName, setCompanyName] = useState("");
    const [fromName, setFromName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [messageText, setMessageText] = useState("");

    const form = useRef();
    const service_ID = "service_8zjnjge";
    const template_ID = "template_9id9xlp";
    const public_key = "1lTaqOz7GxaL2eRPG";

    function emailValidator(email) {
        const re = /\S+@\S+\.\S+/
        if (!re.test(email)) return 'We need a valid email address.'
        return ''
    }
    
    const sendEmail = (e) => {
        e.preventDefault();

        if(companyName == "" || fromName == "" || emailAddress == "") {
            setErrorMessage("Marked fields are mandatory!");
            setOpenError(true);
        
        } else if(emailValidator(emailAddress) != "") {
            setErrorMessage(emailValidator(emailAddress));
            setOpenError(true);

        } else {
            emailjs.sendForm(service_ID, template_ID, form.current, public_key)
                .then((result) => {
                    console.log(result.text);
                    setSuccessMessage("Thank you for your message!\nOur team will review it shortly and will contact you as soon as they can.");
                    setOpenSuccess(true);
                }, (error) => {
                    console.log(error.text);
                    setErrorMessage("Oops! Something went wrong when trying to send your message.\nPlease try again in a few minutes");
                    setOpenError(true);
                });
        }
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenError(false);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSuccess(false);
    };

    return (
        <contact id="contact">
            <div className="contactFormHeader">
                <span className="contactFormHeaderTitle">
                    Contact Us
                </span>
            </div>
            <form ref={form} onSubmit={sendEmail}>
                <Typography variant="overline" display="block" gutterBottom className="form-notification">
                    (*) Starred fields are mandatory!
                </Typography>
                <div className="contact-form">
                    <div className="text-field">
                        <TextField
                            id="company_name"
                            name="company_name"
                            label="*Company name"
                            type="Company name"
                            variant="filled"
                            onChange={(e) => setCompanyName(e.target.value)}
                            className="text-fields"
                        />
                    </div>
                    <div className="text-field">
                        <TextField
                            id="from"
                            name="from"
                            label="*Your name"
                            type="Your name"
                            variant="filled"
                            onChange={(e) => setFromName(e.target.value)}
                            className="text-fields"
                        />
                    </div>
                    <div className="text-field">
                        <TextField
                            id="email"
                            name="email"
                            label="*Business email"
                            type="Email"
                            variant="filled"
                            onChange={(e) => setEmailAddress(e.target.value)}
                            className="text-fields"
                        />
                    </div>
                    <div className="text-field">
                        <TextField
                            id="phone"
                            name="phone"
                            label="Phone number"
                            type="phone"
                            variant="filled"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="text-fields"
                        />
                    </div>
                    <div className="text-field">
                        <TextField
                            id="message"
                            name="message"
                            label="Message"
                            multiline
                            variant="filled"
                            rows={5}
                            onChange={(e) => setMessageText(e.target.value)}
                            className="text-fields"
                        />
                    </div>
                    <Button text="SEND" type="submit" onClick={sendEmail} />
                </div>
            </form>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleErrorClose}>
                <Alert
                    onClose={handleErrorClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                    >
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleSuccessClose}>
                <Alert
                    onClose={handleSuccessClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                    >
                    {successMessage}
                </Alert>
            </Snackbar>
        </contact>
    );
};