import React from 'react';
import Button from './Button';
import '../css/Hero.css';
import { HashLink as Link } from 'react-router-hash-link'; 

export default function Hero() {

  function handleClick() {
    alert('Button clicked!');
  };
  
  return (
    <hero>
        <img
          loading="lazy"
          srcSet="./assets/hero.jpg"
          className="mainImg"
        />
        <div className="heroInner">
          <span>
            <h2>Global Expansion Decisions Made Easy</h2>
            <h5>
            Small-World.ai revolutionize the way companies approach global expansion. Leveraging an AI-powered platform, we provide instant access to detailed, personalized reports.<br /><br />
            These reports, rich with real-time data, streamline the scaling process, ensures regulatory adherence, and offer crucial operational insights. This facilitates swift, well-informed decision-making and optimizes cost-efficiency, transforming growth strategies.
            </h5>
            <Link to="#contact" smooth><Button text="Request a report" /></Link>
          </span>
        </div>
    </hero>
  );
};
