import React from 'react';
import Button from './Button';
import '../css/About.css';
import { HashLink as Link } from 'react-router-hash-link'; 

export default function About() {

    function handleClick() {
      alert('Button clicked!');
    };

    return (
      <about id="about">
        <div className="aboutTitle">
          <span className="aboutTitleMain">
            A bit about us
          </span>
          <br />
          <span className="aboutTitleSub">
            Committed to your growth
          </span>
        </div>
        <div className="aboutInfo">
          At Small-World.ai, we are dedicated to driving your business towards
          sustainable growth.
          <br />
          Our team is committed to delivering unparalleled expertise and
          tailored solutions to meet your unique needs.
          <br />
          <br />
          <span className="aboutInfoTxt">
            Start your journey with Small-World.ai now!
          </span>
          <br />
          <br />
          Reach out to Small-World.ai to embark on a collaborative journey
          towards achieving your business growth goals.
          <br />
          Let's explore the possibilities together!
          <br />
          <br />
        </div>
        <Link to="#contact" smooth><Button text="EXPLORE NOW!" /></Link>
      </about>
    );
  };
  